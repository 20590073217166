
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import {
    StoryData,
    StoryblokBlokData,
    StoryblokComponent,
    getStoryblokApi,
    useStoryblokState
} from "@storyblok/react";
import { VacancyProps } from "storyblok/components";
import styled from "styled-components";
import type { GetStaticPropsContext, NextPage } from "next";

import { HomepageBackground } from "components/homepage";
import { Page } from "components/shared";

import { getStoryblokPageData } from "helpers/storyblok";
import { media } from "constants/media";
import {
    retrieveMysolutionVacancies,
    retrieveZzpVacancies
} from "lib/api/vacancies";

interface StoryProps {
    preview: boolean;
    story?: StoryData;
    vacancies: Array<VacancyProps>;
    zzpVacancies: Array<VacancyProps>;
}

const HomepageBackgroundFallback = styled.div`
    ${media.mdUp} {
        /* stylelint-disable */
        background: linear-gradient(
            180deg,
            var(--color-primary),
            var(--color-orange-40)
        );
        /* stylelint-enable */
        height: 2000px;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
    }
`;

const Home: NextPage<StoryProps> = ({
    story: initialStory,
    vacancies,
    zzpVacancies
}: StoryProps) => {
    const story = useStoryblokState(initialStory);
    const { blocks, seo } = getStoryblokPageData(story);

    return (
        <>
            <Page title={seo?.title} description={seo?.description}>
                <HomepageBackgroundFallback />
                <HomepageBackground>
                    {blocks?.map((block: StoryblokBlokData) => {
                        const additionalProps = block.component ===
                            "VacancySlider" && {
                            vacancies,
                            zzpVacancies
                        };
                        return (
                            <StoryblokComponent
                                key={block._uid}
                                blok={block}
                                {...additionalProps}
                            />
                        );
                    })}
                </HomepageBackground>
            </Page>
        </>
    );
};

 async function _getStaticProps({ preview }: GetStaticPropsContext) {
    const storyblokApi = getStoryblokApi();
    const slug = "home";
    const draftMode = preview
        ? "draft"
        : (process.env.NEXT_PUBLIC_STORYBLOK_VERSION as "draft" | "published");
    const isPreview = draftMode === "draft";

    const {
        data: { story }
    } = await storyblokApi.getStory(slug, {
        version: draftMode
    });

    const vacancies = await retrieveMysolutionVacancies();
    const zzpVacancies = await retrieveZzpVacancies();

    return {
        props: {
            preview: preview ?? false,
            story: story ?? null,
            vacancies: vacancies ?? null,
            zzpVacancies: zzpVacancies ?? null
        },
        revalidate: isPreview ? 1 : 3600
    };
}

export default Home;


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  